import React from "react";
import { useI18next } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";
import { useTranslation } from "react-i18next";
import { getImage } from "gatsby-plugin-image";

import {
  container,
  details as detailsClass,
  mainText,
  keyContact,
  bottomNavigation,
  next,
  prev,
  arrow as arrowClass,
  rightArrow,
  leftArrow,
} from "./expertise.module.scss";
import { IQueryAllResult } from "../models/query-all-result.model";
import { getNodes } from "../utils/get-nodes";

import MainLayout from "../layouts/main-layout";
import Title from "../components/atoms/title";
import Detail from "../components/molecules/datail";
import ReactMarkdown from "react-markdown";
import KeyContact from "../components/molecules/key-contact";
import { Link } from "gatsby-plugin-react-i18next";
import Arrow from "../assets/images/svg/arrow.svg";

interface IExpertiseProps {
  className: string;
  readonly data: {
    allStrapiExpertise: IQueryAllResult<any>;
  };
}

const ExpertisePage: React.FC<IExpertiseProps> = ({ data }) => {
  const { allStrapiExpertise } = data;
  const { navigate } = useI18next();
  const expertises = getNodes(allStrapiExpertise)[0];

  const {
    details_expertise: details,
    childStrapiExpertiseMainTextTextnode: main_text,
    title,
    people,
    next_expertise,
    prev_expertise,
  } = expertises || {};

  if (!title && typeof window !== "undefined") {
    navigate("/");
  }

  return (
    <MainLayout titleSeo={title} descriptionSeo={title}>
      {title && (
        <Title Tag="h1" size="large" align="left" isUnderscored={false}>
          {title}
        </Title>
      )}
      <div className={container}>
        <div className={detailsClass}>
          {main_text && (
            <ReactMarkdown className={mainText}>
              {main_text.main_text}
            </ReactMarkdown>
          )}

          {details?.map((detail, index) => {
            const image = getImage(detail.Icon?.localFile);

            return (
              <Detail
                key={index}
                image={image}
                title={detail.detail_title}
                text={detail.detail_text.data.detail_text}
              />
            );
          })}
        </div>
        {people && (
          <div className={keyContact}>
            <KeyContact
              name={people.Name}
              surname={people.Surname}
              position1={people.Position_1}
              slug={people.slug}
              gatsbyImageAll={people.img_profile}
            />
          </div>
        )}
      </div>

      {(prev_expertise || next_expertise) && (
        <div className={bottomNavigation}>
          {prev_expertise && (
            <div className={prev}>
              <Arrow className={`${arrowClass} ${leftArrow}`} />
              <Link to={`/${prev_expertise.slug}`}>
                {prev_expertise.title}{" "}
              </Link>
            </div>
          )}
          {next_expertise && (
            <div className={next}>
              <Link to={`/${next_expertise.slug}`}>
                {next_expertise.title}{" "}
              </Link>
              <Arrow className={`${arrowClass} ${rightArrow}`} />
            </div>
          )}
        </div>
      )}
    </MainLayout>
  );
};

export const query = graphql`
  query ($language: String!, $slug: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allStrapiExpertise(
      filter: { slug: { eq: $slug }, locale: { eq: $language } }
    ) {
      edges {
        node {
          title
          slug
          childStrapiExpertiseMainTextTextnode {
            main_text
          }
          next_expertise {
            slug
            title
          }
          prev_expertise {
            slug
            title
          }
          details_expertise {
            detail_title
            detail_text {
              data {
                detail_text
              }
            }
            Icon {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          people {
            Position_1
            Position_2
            Surname
            Name
            slug
            img_profile {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default ExpertisePage;
