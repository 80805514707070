import React, { useEffect } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import ReactMarkdown from "react-markdown";
import AOS from "aos";
import "aos/dist/aos.css";

import {
  container,
  titleContainer,
  text as textClass,
  imageContainer,
  image as imageClass,
  title as titleClass,
} from "./detail.module.scss";

interface IDetailProps {
  className?: string;
  title?: string;
  text?: string;
  image?: any;
}

const Detail: React.FC<IDetailProps> = ({
  className = "",
  title,
  text,
  image,
}) => {
  useEffect(() => {
    AOS.init({ once: true });
  }, []);
  return (
    <div className={`${className} ${container}`} data-aos="fade-up">
      {title && (
        <div className={titleContainer}>
          {image && (
            <div className={imageContainer}>
              <GatsbyImage alt={""} image={image} className={imageClass} />
            </div>
          )}
          <h3 className={titleClass}>{title}</h3>
        </div>
      )}
      <div>
        <ReactMarkdown className={textClass}>{text}</ReactMarkdown>
      </div>
    </div>
  );
};

export default Detail;
