import React from "react";
import { Link } from "gatsby";

import {
  container,
  img,
  title,
  detail,
  fakeButton,
  details,
  keyContact,
} from "./key-contact.module.scss";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useTranslation } from "react-i18next";
import Title from "../atoms/title";

interface IPersonCardProps {
  className?: string;
  name: string;
  surname: string;
  slug: string;
  degree?: string;
  position1?: string;
  position2?: string;
  gatsbyImageAll: any;
}

const PersonCard: React.FC<IPersonCardProps> = ({
  className = "",
  name,
  surname,
  slug,
  degree,
  position1,
  position2,
  gatsbyImageAll,
}) => {
  const { t } = useTranslation();

  const { alternativeText, localFile } = gatsbyImageAll || {};

  const gatsbyImage = getImage(localFile);

  return (
    <div>
      <Title Tag="h4" size={"medium"} className={keyContact}>
        {t("key.kontakt")}
      </Title>
      <Link to={`/${slug}`} className={`${className} ${container}`}>
        {gatsbyImage && (
          <GatsbyImage
            alt={alternativeText}
            image={gatsbyImage}
            className={img}
            objectFit={"cover"}
            objectPosition={"top"}
          />
        )}
        <div className={details}>
          <p className={title}>
            {name}
            <br /> {surname}
          </p>
          {degree && <p className={detail}>{degree}</p>}
          <p className={detail}>{position1}</p>
          <span className={fakeButton}>{t("see.profile")}</span>
        </div>
      </Link>
    </div>
  );
};

export default PersonCard;
